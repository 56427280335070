import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/var/www/host/nsfmsfrontendv2/src/@core/contexts/settingsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/@layouts/components/vertical/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/@layouts/components/vertical/LayoutContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/@layouts/components/vertical/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/@layouts/LayoutWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VerticalNavProvider"] */ "/var/www/host/nsfmsfrontendv2/src/@menu/contexts/verticalNavContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/components/Breadcrumb/NextBreadcrumb.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/components/layout/shared/ModeDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/components/layout/shared/UserDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/components/layout/vertical/FooterContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/components/layout/vertical/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/components/layout/vertical/NavToggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/nsfmsfrontendv2/src/components/theme/index.tsx");
