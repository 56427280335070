// 'use client'

// import React, { ReactNode } from 'react'

// import { usePathname } from 'next/navigation'
// import Link from 'next/link'

// type TBreadCrumbProps = {
//     homeElement: ReactNode,
//     separator: ReactNode,
//     containerClasses?: string,
//     listClasses?: string,
//     activeClasses?: string,
//     capitalizeLinks?: boolean
// }

// const NextBreadcrumb = ({homeElement, separator, containerClasses, listClasses, activeClasses, capitalizeLinks}: TBreadCrumbProps) => {

//     const paths = usePathname()
//     const pathNames = paths.split('/').filter( path => path )

//     return (
//         <div>
//             <ul className={containerClasses}>
//                 <li className={listClasses}><Link href={'/'}>{homeElement}</Link></li>
//                 {pathNames.length > 0 && separator}
//             {
//                 pathNames.map( (link, index) => {
//                     let href = `/${pathNames.slice(0, index + 1).join('/')}`
//                     let itemClasses = paths === href ? `${listClasses} ${activeClasses}` : listClasses
//                     let itemLink = capitalizeLinks ? link[0].toUpperCase() + link.slice(1, link.length) : link
//                     return (
//                         <React.Fragment key={index}>
//                             <li className={itemClasses} >
//                                 <Link href={href}>{itemLink}</Link>
//                             </li>
//                             {pathNames.length !== index + 1 && separator}
//                         </React.Fragment>
//                     )
//                 })
//             }
//             </ul>
//         </div>
//     )
// }

// export default NextBreadcrumb

'use client'

// React Imports
import { useState } from 'react'
import React, { ReactNode } from 'react'

import { usePathname } from 'next/navigation'

// MUI Imports

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from "@/components/Link";
// import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

type TBreadCrumbProps = {
    homeElement: ReactNode,
    container?: string,
    listClasses?: string,
    activeClasses?: string,
    capitalizeLinks?: boolean
}

const NextBreadcrumb = ({ homeElement, listClasses, activeClasses, capitalizeLinks, container }: TBreadCrumbProps) => {
    const paths = usePathname()
    const pathNames1 = paths.split('/').filter(path => path)

    // States
    // const [open, setOpen] = useState(false)
    // const [tooltipOpen, setTooltipOpen] = useState(false)

    const getModeIcon = () => {
        return 'ri-arrow-right-s-fill'
    }
    console.log("pathNames index", pathNames1.length);
    if (pathNames1?.length > 1) {
        var index = pathNames1.indexOf("detail"); 
        var index1 = pathNames1.indexOf("edit")
        var index2 = pathNames1.indexOf("users")
        console.log("pathNames1 index va", index);
        if (index > -1) {
            delete pathNames1[index];
        }
        if (index1 > -1) {
            delete pathNames1[index1];
        }
        if (index2 > -1) {
            delete pathNames1[index2];
        }
    }

    const pathNames = pathNames1;

    console.log("pathNames index reset", pathNames);
    return (
        <>
            {/* <Tooltip
                title={'Navigator'}
                onOpen={() => setTooltipOpen(true)}
                onClose={() => setTooltipOpen(false)}
                open={open ? false : tooltipOpen ? true : false}
                PopperProps={{ className: 'capitalize' }}
            >
                <i className={getModeIcon()} />
            </Tooltip> */}
            <Breadcrumbs aria-label='breadcrumb' className={container}>
                <Typography color='primary' className="font-bold" component={Link}>
                    {homeElement}
                </Typography>
                {pathNames.map((link, index) => {
                    var sub_url = ""
                    if (index2 > -1) {
                        sub_url = "/users"
                    }
                    let href = `${sub_url}/${pathNames.slice(0, index + 1).join('/')}`
                    let itemClasses = paths === href ? `${listClasses} ${activeClasses}` : listClasses
                    let itemLink = capitalizeLinks ? link[0].toUpperCase() + link.slice(1) : link
                    const last = pathNames.slice(-1);
                    console.log("last index", last[0]);
                    console.log("last index itemLink", itemLink.toLowerCase());
                    console.log("href", href.replace(/\/\//g, "/"));
                    return (
                        <>
                            {(last[0].toLowerCase() === itemLink.toLowerCase()                                                                                            ) ? (
                                <Typography key={index} color="primary" >
                                    {itemLink.charAt(0).toUpperCase().replace("%20", " ") + itemLink.slice(1).replace("-", " ").replace("%20", " ")}
                                </Typography>
                            ) : (
                                <Typography key={index} className='font-bold' color='primary' component={Link} href={href.replace(/\/\//g, "/")} >
                                    {itemLink.charAt(0).toUpperCase().replace("%20", " ") + itemLink.slice(1).replace("-", " ").replace("%20", " ")}
                                </Typography>
                            )}
                        </>
                    )
                })}
            </Breadcrumbs>
        </>
    )
}

export default NextBreadcrumb